import moment from "moment";

export const SATURDAY = 6;

export const getNextDay = (dayIndex) => {
    const today = moment().isoWeekday();
    if (today <= dayIndex) {
        return moment().isoWeekday(dayIndex).startOf('day');
    } else {
        return moment().add(1, 'weeks').isoWeekday(dayIndex).startOf('day');
    }
}