import React, {useEffect, useState} from "react";
import {restInstance} from "../domain/http/rest";
import {OrderStatus} from "../domain/orders/OrderStatus";

export const OrdersContext = React.createContext("orders");

export const OrdersProvider = ({children}) => {
    const [status, setStatus] = useState(OrderStatus.INCOMING);
    const [loaded, setLoaded] = useState(false);
    const [orders, setOrders] = useState([]);
    const [exportedOrders, setExportedOrders] = useState([]);

    const loadOrders = async status => {
        if (status === OrderStatus.INCOMING)
            setLoaded(true);

        const {data} = await restInstance.get(`/orders/${status}`);
        status === OrderStatus.EXPORTED ? setExportedOrders(data) : setOrders(data);
        if (status === OrderStatus.INCOMING)
            setLoaded(false);
    }

    const generateReport = async () => {
        const {data} = await restInstance({
            url: `/orders/export`,
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Accept': 'application/csv',
                'Content-Type': 'application/json'
            }
        })
        const blob = new Blob([data], {type: 'application/csv'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        link.download = `export-${Date.now()}.csv`;
        document.body.appendChild(link);
        link.click();
        link.remove();


        loadOrders(OrderStatus.INCOMING);
        loadOrders(OrderStatus.EXPORTED);
    };

    const transferOrders = async () => {
        const allOrders = [...orders, ...exportedOrders];
        const ids = allOrders.map(o => o._id);
        await restInstance.put('/orders/in-work', {ids});
        loadOrders(OrderStatus.INCOMING);
        loadOrders(OrderStatus.EXPORTED);
    }

    useEffect(() => {
        loadOrders(status);
        if (status === OrderStatus.INCOMING) {
            loadOrders(OrderStatus.EXPORTED);
        }
    }, [status]);

    return <OrdersContext.Provider
        value={{
            orders,
            exportedOrders,
            status,
            setStatus,
            loaded,
            generateReport,
            transferOrders
        }}>{children}</OrdersContext.Provider>;
};
