import axios from "axios";

export const restInstance = axios.create({
    baseURL: getApiURL(),
    headers: { "Content-Type": "application/json" },
    validateStatus: (status) => status < 500,
});

restInstance.defaults.headers.common['X-Access-Token'] = localStorage.getItem("auth_token") || "";

export const setToken = token => {
    if (!token)
        return localStorage.removeItem("auth_token");

    localStorage.setItem("auth_token", token);
    restInstance.defaults.headers.common['X-Access-Token'] = token;
};


function getApiURL() {
    const host = location.host;
    if (host.indexOf("localhost") >= 0) {
        return "http://localhost:3000";
    } else {
        return "https://s10-dashboard.routeme.life/api";
    }
}