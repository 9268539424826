import React, { useEffect, useState } from "react";
import { restInstance } from "../domain/http/rest";

export const StatisticsContext = React.createContext("statistics");

export const StatisticsProvider = ({ children }) => {
    const [statistics, setStatistics] = useState([]);

    const loadStatistics = async () => {
        const { data } = await restInstance.get(`/statistics/challenges`);

        for (let i = 0; i < data.statistics.length; i++) {
            if (i === data.statistics.length - 1) continue;

            data.statistics[i].registrationsRelative = calculateConversion(data.statistics[i].registrations, data.statistics[i + 1].registrations);
            data.statistics[i].freshsRelative = calculateConversion(data.statistics[i].freshs, data.statistics[i + 1].freshs);
            data.statistics[i].oldtimersRelative = calculateConversion(data.statistics[i].oldtimers, data.statistics[i + 1].oldtimers);
            data.statistics[i].totalUniqueParticipantsRelative = calculateConversion(data.statistics[i].totalUniqueParticipants, data.statistics[i + 1].totalUniqueParticipants);
        }

        setStatistics(data.statistics);
    };

    const calculateConversion = (a, b) => {
        if (!a || !b) return 0;

        return (a / b * 100 - 100).toFixed(2);
    }

    useEffect(() => {
        loadStatistics();
    }, []);

    return <StatisticsContext.Provider
        value={{
            statistics
        }}>{children}</StatisticsContext.Provider>;
};
