import React from "react";

import Header, { pages } from "../Header";
import { TrackContainer } from "../../components/tracks/TrackContainer";

export const TrackPage = () => {
    return (
        <>
            <Header activePage={pages.TRACKS} />
            <TrackContainer />
        </>
    );
};