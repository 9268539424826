import React, {useEffect, useState} from "react";

import {restInstance} from "../domain/http/rest";

export const ChallengesContext = React.createContext("challenges");

const createFormData = (registrationData, challengeData, distances, bannerImg, storyImg, settings) => {
    const formData = new FormData();

    Object.keys(registrationData).forEach(key => {
        formData.append(`registration[${key}]`, registrationData[key]);
    });

    Object.keys(challengeData).forEach(key => {
        formData.append(`challenge[${key}]`, challengeData[key]);
    });

    Object.keys(settings).forEach(key => {
        formData.append(`settings[${key}]`, settings[key]);
    });

    distances.forEach((d, index) => {
        Object.keys(d).forEach(key => {
            formData.append(`challenge[distances][${index}][${key}]`, d[key]);
        });
    });

    formData.append("bannerImg", bannerImg);
    formData.append("storyImg", storyImg);

    return formData
}

export const ChallengesProvider = ({children}) => {
    const [challenges, setChallenges] = useState([]);
    const [challenge, setChallenge] = useState(null);
    const [formulas, setFormulas] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [challengeFilter, setChallengeFilter] = useState("active");


    const loadChallenges = async () => {
        const {data} = await restInstance.get("/challenges");
        if (data) {
            data.sort((a, b) => a.challenge.runStartDate < b.challenge.runStartDate ? 1 : -1)
            setChallenges(data);
        }
    };

    const getSelectedChallenge = async (registrationId) => {
        const {data} = await restInstance.get(`/challenges/${registrationId}`);
        setChallenge(data);
    };

    const loadFormulas = async () => {
        const {data} = await restInstance.get("/challenges/formulas");
        setFormulas(data);
    };

    const loadSeasons = async () => {
        const {data} = await restInstance.get("/seasons");
        setSeasons(data);
    }

    const launchChallenge = async (registrationData, challengeData, distances, bannerImg, storyImg, settings) => {
        const formData = createFormData(registrationData, challengeData, distances, bannerImg, storyImg, settings)

        await restInstance.post(`/challenges`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    const updateChallenge = async (registrationId, registrationData, challengeData, distances, bannerImg, storyImg, settings) => {
        const formData = createFormData(registrationData, challengeData, distances, bannerImg, storyImg, settings)

        await restInstance.put(`/challenges/${registrationId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    const deleteChallenge = async (challengeId) => {
        await restInstance.delete(`/challenges/${challengeId}`);
    }

    const updateChallengeStage = async (challengeId, stage) => {
        const {data} = await restInstance.put(`/challenges/${challengeId}/stage`, {stage});
        const newChallenges = challenges.filter(c => c.challenge.id !== challengeId);
        newChallenges.push(data);
        newChallenges.sort((a, b) => a.challenge.runStartDate < b.challenge.runStartDate ? 1 : -1);
        setChallenges(newChallenges);
    };

    const calculateRating = async (challengeKey, challengeId) => {
        const {data} = await restInstance.put(`/challenges/${challengeId}/rating`, {challengeKey});
        const newChallenges = challenges.filter(c => c.challenge.id !== challengeId);
        newChallenges.push(data);
        newChallenges.sort((a, b) => a.challenge.runStartDate < b.challenge.runStartDate ? 1 : -1);
        setChallenges(newChallenges);
    };

    useEffect(async () => {
        await Promise.all([loadSeasons(), loadFormulas()]);
        await loadChallenges();
    }, []);

    return (
        <ChallengesContext.Provider
            value={{
                launchChallenge, updateChallengeStage,
                challenges,
                formulas,
                seasons,
                challenge, getSelectedChallenge,
                updateChallenge, deleteChallenge,
                calculateRating,
                challengeFilter,
                setChallengeFilter,
            }}>
            {children}
        </ChallengesContext.Provider>
    );
};
