import { makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import cs from 'classnames';

import { StatisticsContext } from '../../../contexts/Statistics';


export const StatisticsContainer = () => {

    const statisticsContext = useContext(StatisticsContext);
    const statistics = statisticsContext.statistics || [];

    const classes = useStyles();

    const getProgressClasses = (value) => {
        return cs(classes.progress, value > 0 ? classes.positive : classes.negative);
    }

    return (
        <Container>
            {statistics.map(s =>
                <Paper className={classes.container} key={s.id}>
                    <Typography variant="subtitle1" className={classes.challengeName}>
                        {s.challengeName}
                        <div className={classes.runWindow}>{moment(s.runStart).format("YYYY-MM-DD")} - {moment(s.runStop).format("YYYY-MM-DD")}</div>
                    </Typography>
                    <div className={classes.statisticsContainer}>
                        <Paper className={classes.statistics}>
                            <div className={classes.label}>Регистрации</div>
                            <div className={classes.value}>{s.registrations}</div>
                            {s.registrationsRelative && <div className={getProgressClasses(s.registrationsRelative)}>
                                {s.registrationsRelative > 0 ? `+${s.registrationsRelative}%` : `${s.registrationsRelative}%`}
                            </div>}
                        </Paper>
                        <Paper className={classes.statistics}>
                            <div className={classes.label}>В протоколе</div>
                            <div className={classes.value}>{s.inProtocol}</div>
                        </Paper>
                        <Paper className={classes.statistics}>
                            <div className={classes.label}>DSQ</div>
                            <div className={classes.value}>{s.dsq}</div>
                        </Paper>
                        <Paper className={classes.statistics}>
                            <div className={classes.label}>Total</div>
                            <div className={classes.value}>{s.totalUniqueParticipants}</div>
                            {s.totalUniqueParticipantsRelative && <div className={getProgressClasses(s.totalUniqueParticipantsRelative)}>
                                {s.totalUniqueParticipantsRelative > 0 ? `+${s.totalUniqueParticipantsRelative}%` : `${s.totalUniqueParticipantsRelative}%`}
                            </div>}
                        </Paper>
                        <Paper className={classes.statistics}>
                            <div className={classes.label}>1 challenge</div>
                            <div className={classes.value}>{s.freshs}</div>
                            {s.freshsRelative && <div className={getProgressClasses(s.freshsRelative)}>
                                {s.freshsRelative > 0 ? `+${s.freshsRelative}%` : `${s.freshsRelative}%`}
                            </div>}
                        </Paper>
                        <Paper className={classes.statistics}>
                            <div className={classes.label}>&gt; 5 challenges</div>
                            <div className={classes.value}>{s.oldtimers}</div>
                            {s.oldtimersRelative && <div className={getProgressClasses(s.oldtimersRelative)}>
                                {s.oldtimersRelative > 0 ? `+${s.oldtimersRelative}%` : `${s.oldtimersRelative}%`}
                            </div>}
                        </Paper>
                    </div>
                </Paper>)
            }
        </Container>
    );
};

const Container = styled.div`
max-width: 1024px;
margin: 2rem auto;
`;

const useStyles = makeStyles({
    container: {
        '&:not(:last-child)': {
            marginBottom: '2rem'
        },
        padding: "1.5rem",
    },
    challengeName: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    runWindow: {
        marginLeft: "1rem"
    },
    statisticsContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: "1rem",
        flexWrap: "wrap"
    },
    statistics: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "1rem"
    },
    positive: {
        color: "green"
    },
    negative: {
        color: "red"
    },
    label: {
        fontSize: "1.15rem",
    },
    value: {
        fontWeight: "bold"
    }
});