import ruLocales from './ru';
import {sprintf} from 'sprintf-js';

const locales = {
    ru: ruLocales
};

const defaultLocale = 'ru';

export const getTrans = (key, ...args) => {
    const string = locales[defaultLocale][key];

    if (args.length === 0 || !string)
        return string ? string : key;

    return sprintf(string, args);
}