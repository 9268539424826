import React, { useContext, useEffect } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { ChallengesContext } from "../../../contexts/Challenges";
import { LaunchChallenge } from './LaunchChallenge';
import { useParams } from "react-router-dom"


export const ChallengeContainer = () => {
    const classes = useStyles();
    const challengesContext = useContext(ChallengesContext);
    const { registrationId } = useParams();
    useEffect(() => {
        challengesContext.getSelectedChallenge(registrationId)
    }, [])
    return (<>
        {
            challengesContext.challenge ? 
            <LaunchChallenge registration={challengesContext.challenge} update />
            : <div className={classes.progressContainer}>
                <CircularProgress/>
            </div>
        }
    </>);
};

const useStyles = makeStyles({
    progressContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "5rem"
    }
})
