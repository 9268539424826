import React, { useContext, useEffect } from "react";

import Header, { pages } from "../Header";
import { TracksContainer } from "../../components/tracks/TracksContainer";
import { TracksContext } from "../../../contexts/Tracks";

export const TracksPage = () => {
  const tracksContext = useContext(TracksContext);
  useEffect(() => {
    tracksContext.loadTracks();
  }, []);

  return (
    <>
      <Header activePage={pages.TRACKS} />
      <TracksContainer />
    </>
  );
};
