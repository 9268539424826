import React, {useContext} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {AuthContext} from "../../contexts/Auth";
import SignIn from "../components/auth/AuthContainer";
import {ShopPage} from "../pages/ShopPage";
import {ChallengesPage} from "../pages/ChallengesPage";
import {ChallengePage} from "../pages/ChallengePage";
import {LaunchChallengePage} from "../pages/LaunchChallengePage";
import {StatisticsPage} from "../pages/StatisticsPage";
import {ClubsPage} from "../pages/clubs/ClubsPage";
import {ClubPage} from "../pages/clubs/ClubPage";
import {CreateClubPage} from "../pages/clubs/CreateClubPage";
import { TracksPage } from "../pages/tracks/TracksPage";
import { TrackPage } from "../pages/tracks/TrackPage";

export default () => {
    const authContext = useContext(AuthContext);

    if (authContext.loading) return null;

    if (!authContext.loading && !authContext.currentUser) {
        return <SignIn/>;
    }

    return (
        <Router>
            <Switch>
                <Route path="/shop" exact>
                    <ShopPage/>
                </Route>
                <Route path="/" exact>
                    <ChallengesPage/>
                </Route>
                <Route path="/challenges/new" exact>
                    <LaunchChallengePage/>
                </Route>
                <Route path="/challenges/:registrationId" exact>
                    <ChallengePage/>
                </Route>
                <Route path="/statistics" exact>
                    <StatisticsPage/>
                </Route>
                <Route path="/clubs" exact>
                    <ClubsPage/>
                </Route>
                <Route path="/clubs/new" exact>
                    <CreateClubPage/>
                </Route>
                <Route path="/clubs/:clubId" exact>
                    <ClubPage/>
                </Route>
                <Route path="/tracks" exact>
                    <TracksPage/>
                </Route>
                <Route path="/track/:resourceId" exact>
                    <TrackPage/>
                </Route>
                <Route path="*">Not found</Route>
            </Switch>
        </Router>
    );
};
