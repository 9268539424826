import React, { useContext, useEffect } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { ClubsContext } from "../../../contexts/Clubs";
import { CreateClub } from './CreateClub';
import { useParams } from "react-router-dom"


export const ClubContainer = () => {
    const classes = useStyles();
    const clubsContext = useContext(ClubsContext);
    const { clubId } = useParams();
    useEffect(() => {
        clubsContext.loadClub(clubId)
    }, [])
    return (<>
        {
            clubsContext.club ? 
            <CreateClub club={clubsContext.club} />
            : <div className={classes.progressContainer}>
                <CircularProgress/>
            </div>
        }
    </>);
};

const useStyles = makeStyles({
    progressContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "5rem"
    }
})
