import React from "react";
import { ClubsProvider } from "../../../contexts/Clubs";
import { CreateClub } from "../../components/clubs/CreateClub";

import Header, { pages } from "../Header";

export const CreateClubPage = () => {
    return (
        <ClubsProvider>
            <Header activePage={pages.CLUBS} />
            <CreateClub />
        </ClubsProvider>
    );
};