import React, { useContext } from "react";

import { OrdersProvider } from "../../contexts/Orders";
import Header, { pages } from "./Header";
import { OrdersContainer } from "../components/orders/OrdersContainer";
import { AuthContext } from "../../contexts/Auth";
import { isCanViewShop } from "../../domain/resolvePermission";

export const ShopPage = () => {
    const authContext = useContext(AuthContext);
    if (!isCanViewShop(authContext.currentUser)) {
        window.location.href = '/';
        return null;
    }

    return (
        <OrdersProvider>
            <Header activePage={pages.SHOP} />
            <OrdersContainer />
        </OrdersProvider>
    );
};