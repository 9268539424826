import React, { useContext } from "react";
import { ChallengesContext } from "../../../contexts/Challenges";
import { makeStyles, Dialog, DialogTitle, Grid, Button } from "@material-ui/core";


export const DeleteChallengeDialog = ({ isDialog, setDialog, challengeId }) => {
    const classes = useStyles();
    const { deleteChallenge } = useContext(ChallengesContext);
    
    const handleDeletingChallenge = async () => {
        await deleteChallenge(challengeId)
        window.location = '/'
    };

    return (
        <Dialog open={isDialog} onClose={() => setDialog(!isDialog)}>
            <DialogTitle>Вы точно хотите удалить челлендж?</DialogTitle>
            <Grid className={classes.dialogActions} container justify="space-around">
                <Grid item xs={5}>
                    <Button fullWidth variant="contained" onClick={handleDeletingChallenge} color="secondary">Удалить</Button>
                </Grid>
                <Grid item xs={5}>
                    <Button fullWidth variant="contained" onClick={() => setDialog(!isDialog)}>Отменить</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}


const useStyles = makeStyles({
    dialogActions: {
        paddingBottom: "1rem"
    }
})