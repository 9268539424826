import React from "react";
import styled from "styled-components";

import { TracksList } from "./TracksList";

export const TracksContainer = () => {
  return (
    <Container>
      <TracksList />
    </Container>
  );
};

const Container = styled.div`
  max-width: 1024px;
  margin: 1rem auto;
`;

