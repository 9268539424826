import React from "react";
import styled from "styled-components";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Router from "./routes/Router";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "../contexts/Auth";
import { TracksProvider } from "../contexts/Tracks";

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <SnackbarProvider maxSnack={3}>
      <AppContainer>
        <AuthProvider>
          <TracksProvider>
            <Router />
          </TracksProvider>
        </AuthProvider>
      </AppContainer>
    </SnackbarProvider>
  </MuiPickersUtilsProvider>
);

export default App;

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;
