import "leaflet/dist/leaflet.css";
import React, { useCallback, useEffect, useState } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import Axios from "axios";
import * as gpxParse from "gpx-parse";
import styled from "styled-components";

export const TrackView = ({ gpxFileUrl }) => {
  const [points, setPoints] = useState([]);

  const parseFile = useCallback(async () => {
    const gpxFile = await (
      await Axios.get(gpxFileUrl, { responseType: "blob" })
    ).data.text();

    const gpxData = gpxParse.parseGpx(gpxFile, (err, data) => {
      if (err) return;

      const points = [];

      for (const segment of data.tracks[0].segments) {
        segment.forEach((element) => {
          points.push([element.lat, element.lon]);
        });
      }

      setPoints(points);
    });
  }, [gpxFileUrl]);

  useEffect(() => {
    parseFile();
  }, []);

  if(points.length === 0) return null;

  return (
    <StyledMapContainer center={points[0]} zoom={9} scrollWheelZoom={false}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Polyline
        pathOptions={{ fillColor: "red", color: "blue" }}
        positions={points}
      />
    </StyledMapContainer>
  );
};

const StyledMapContainer = styled(MapContainer)`
  width: 700px;
  height: 500px;
`;
