import React, {useContext} from "react";
import {ClubsContext} from "../../../contexts/Clubs";
import {MenuItem, Select} from "@material-ui/core";

const clubsFilterTypeToName = {
    "all": "Все",
    "open": "Открытые",
    "private": "Закрытые",
    "disabled": "Приостановленные",
};

export const ClubsFilter = () => {
    const clubsContext = useContext(ClubsContext);

    const handleChange = (event) => {
      clubsContext.setClubsFilter(event.target.value);
    };

    return (
        <Select value={clubsContext.clubsFilter} onChange={handleChange}>
            {Object.keys(clubsFilterTypeToName).map(key => (
                <MenuItem key={key} value={key}>{clubsFilterTypeToName[key]}</MenuItem>
            ))}
        </Select>
    );
}