import React, { useContext } from "react";
import styled from "styled-components";
import { ClubsContext } from "../../../contexts/Clubs";

import { Card, CardHeader, CardContent, Typography, Chip, Button } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import EuroIcon from '@material-ui/icons/Euro';
import { AuthContext } from "../../../contexts/Auth";
import { isCanManageClubs } from "../../../domain/resolvePermission";
import { useHistory } from "react-router-dom"

export const ClubsList = () => {
    const clubsContext = useContext(ClubsContext);
    const authContext = useContext(AuthContext);

    const clubs = clubsContext.clubs || [];
    const canManageClubs = isCanManageClubs(authContext.currentUser);

    return (
        <Container>
            {clubs
                .filter(c => {
                    if (clubsContext.clubsFilter === 'all') return true;

                    if (clubsContext.clubsFilter === 'open' && !c.isPrivate && !c.disabled) return true;

                    if (clubsContext.clubsFilter === 'private' && c.isPrivate && !c.disabled) return true;

                    if (clubsContext.clubsFilter === 'disabled' && c.disabled) return true;
                })
                .map(club => <Club
                    key={club.id}
                    club={club}
                    canManage={canManageClubs}
                />)}
        </Container>
    )
};

const Club = ({ club, canManage }) => {
    const history = useHistory();

    return (
        <StyledCard>
            <CardHeader
                title={<div>
                    <CardHeaderTitle>
                        <LogoUrl src={club.logoUrl}/> 
                        { club.name }                        
                    </CardHeaderTitle>
                    <StyledChip
                        label={club.userCount}
                        icon={<PersonIcon />}
                    />
                    <StyledChip
                        label={club.isPrivate ? "Закрытый" :  "Открытый"}
                        color={club.isPrivate ? "secondary" :  "primary"}
                    />
                    {club.hasMembershipFee && <StyledChip
                        icon={<EuroIcon />}
                    />}
                    {club.disabled && <StyledChip
                        label={"Приостановлен"}
                    />}
                </div>}
            >
            </CardHeader>
            <CardContent>
                <Typography variant="body1" component="p">
                    <StyledButton 
                        variant="outlined" 
                        color="primary"
                        href={`https://s10.run/club?id=${club.clubKey}`}
                        target="_blank"
                    >
                        Клуб
                    </StyledButton>
                    {
                        Boolean(canManage) &&
                        <StyledButton variant="outlined" color="secondary" onClick={() => history.push(`/clubs/${club.id}`)} >
                            Редактировать
                        </StyledButton>
                    }
                </Typography>
            </CardContent>
        </StyledCard>
    );
};


const Container = styled.div`
`;

const CardHeaderTitle = styled.div`
display: flex;
align-items: center;
margin-bottom: 0.75rem;
`

const LogoUrl = styled.img`
width: 3rem;
height: 3rem;
border-radius: 50%;
margin-right: 1rem;
object-fit:cover;
`

const StyledCard = styled(Card)`
margin-bottom: 1rem;
`;


const StyledButton = styled(Button)`
margin-right:.5rem!important;
`;

const StyledChip = styled(Chip)`
margin-left:0.5rem!important;
`;