import React, { useEffect, useState } from "react";

import { restInstance } from "../domain/http/rest";

export const ClubsContext = React.createContext(undefined);

const createFormData = (clubData, settingsData, logoImg, themeImg) => {
    const formData = new FormData();

    Object.keys(clubData).forEach(key => {
        formData.append(`club[${key}]`, clubData[key]);
    });

    Object.keys(settingsData).forEach(key => {
        formData.append(`settings[${key}]`, settingsData[key]);
    });

    formData.append("logoImg", logoImg);
    formData.append("themeImg", themeImg);

    return formData
}

export const ClubsProvider = ({ children }) => {
    const [clubs, setClubs] = useState([]);
    const [club, setClub] = useState(null);
    const [clubsFilter, setClubsFilter] = useState("open");

    const loadClubs = async () => {
        try {
            const { data } = await restInstance.get("/clubs/users-count");
            setClubs(data);
        } catch (e) {
            console.log("loadClubs", e);
        }
    };

    useEffect(() => {
        loadClubs();
    }, []);

    const loadClub = async (clubId) => {
        try {
            const { data } = await restInstance.get(`/clubs/${clubId}`);
            setClub(data);
        } catch (e) {
            console.log("loadClub", e);
        }
    };


    const createClub = async (clubData, settingsData, logoImg, themeImg) => {
        try {
            const formData = createFormData(clubData, settingsData, logoImg, themeImg)
            await restInstance.post(`/clubs`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            console.log("createClub", e);
        }
    };

    const updateClub = async (clubId, clubData, clubSettingsData, logoImg, themeImg) => {
        try {
            const formData = createFormData(clubData, clubSettingsData, logoImg, themeImg)
            await restInstance.put(`/clubs/full/${clubId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            console.log("updateClub", e);
        }
    };

    const deleteClub = async (clubId) => {
        await restInstance.delete(`/clubs/${clubId}`);
    }

    const requestAliasCheck = async (alias) => {
        try {
            const response = await restInstance.get(`/clubs/check-alias?alias=${encodeURIComponent(alias)}`)
            return !!response.data.isTaken;
        } catch (e) {
            return false;
        }
    }

    return (
        <ClubsContext.Provider
            value={{
                createClub,
                clubs,
                club, loadClub,
                updateClub, deleteClub,
                clubsFilter, setClubsFilter,
                requestAliasCheck,
            }}>
            {children}
        </ClubsContext.Provider>
    );
};
