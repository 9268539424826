import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { isString } from "lodash"
import { validate } from "@material-ui/pickers";


export const ImageLoader = ({ type, onChange, value, className }) => {
    const inputRef = useRef();
    const imgRef = useRef();

    const label = getLabel(type);
    const Container = getContainer(type);
    const ImageView = type === ImageLoaderType.CUSTOM ? ContainImage : CoverImage;

    const handleChange = (e) => {
        const file = e.target.files[0];
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            URL.revokeObjectURL(img.src);
            if ((type === ImageLoaderType.CUSTOM) && !validateSizeCustomImage(img))
                return alert("Неподходящий размер изображения. Ширина и высота должна быть не меньше 200 px"); 
            onChange(file);
        };
    }

    useEffect(() => {
        if (!value) return;

        imgRef.current.src = isString(value) ? value : URL.createObjectURL(value);
        imgRef.current.onload = function () {
            URL.revokeObjectURL(imgRef.current.src);
        }
    }, [value])

    return (
        <Container onClick={() => { inputRef.current.click() }} className={className}>
            {label}
            <input type="file" hidden accept="image/*" ref={inputRef} onChange={handleChange} />
            <ImageView isImg={Boolean(value)} ref={imgRef} />
        </Container>
    );
};

export const ImageLoaderType = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal',
    CUSTOM: 'custom',
    THEME: 'theme',
};

const LoaderContainer = styled.div`
border: #195190 dashed 1px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
color: #195190;
position:relative;
`;

const HorizontalLoaderContainer = styled(LoaderContainer)`
width: 250px;
height: 130px;
`;

const VerticalLoaderContainer = styled(LoaderContainer)`
height: 160px;
width: 100px;
`;

const ThemeLoaderContainer = styled(LoaderContainer)`
height: 300px;
width: 100%;
`;

const SquareLoaderContainer = styled(LoaderContainer)`
height: 200px;
width: 200px;
border:none;
`;



const CoverImage = styled.img`
width:100%;
height: 100%;
position:absolute;
object-fit:cover;
`;

const ContainImage = styled(CoverImage)((props) => ({
    objectFit:"contain",
    backgroundColor: props.isImg ? 'white' : 'transparent'
}))

const getLabel = (type) => {
    if (type === ImageLoaderType.CUSTOM)
        return ">=200x200<=";

    if (type === ImageLoaderType.VERTICAL)
        return "1000x1600";

    if (type === ImageLoaderType.THEME)
        return "100% x 300px";

    return "1000x520";
}

const getContainer = (type) => {
    if (type === ImageLoaderType.CUSTOM)
        return SquareLoaderContainer;
    
    if (type === ImageLoaderType.VERTICAL)
        return VerticalLoaderContainer;
    
    if (type === ImageLoaderType.THEME)
        return ThemeLoaderContainer;

    return HorizontalLoaderContainer;
}

const validateSizeCustomImage = (image) => {
    const minValue = 200;
    const imageWidth = image.width;
    const imageHeight = image.height;

    if (imageWidth < minValue || imageHeight < minValue)
        return false;

    return true;
}