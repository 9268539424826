import React from "react";
import { StatisticsProvider } from "../../contexts/Statistics";
import { StatisticsContainer } from "../components/statistics/StatisticsContainer";

import Header, { pages } from "./Header";

export const StatisticsPage = () => {
    return (
        <StatisticsProvider>
            <Header activePage={pages.STATISTICS} />
            <StatisticsContainer />
        </StatisticsProvider>
    );
};