import React, { useContext } from "react";
import { OrdersContext } from "../../../contexts/Orders";
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { getTrans } from "../../../translations";
import { OrderStatus } from "../../../domain/orders/OrderStatus";
import moment from "moment";
import styled from "styled-components";

export const Orders = ({ status }) => {
    const ordersContext = useContext(OrdersContext);
    const orders = (status === OrderStatus.EXPORTED ? ordersContext.exportedOrders : ordersContext.orders) || [];

    if (ordersContext.loaded && status === OrderStatus.INCOMING) {
        return (<LoaderContainer><CircularProgress /></LoaderContainer>);
    }

    if (orders.length === 0) {
        return <BlockHeader variant="subtitle2" color="textSecondary"
            align="center">{getTrans('no_orders_with_status', getTrans(status))}</BlockHeader>;
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell component="th">{getTrans('order_number')}</TableCell>
                        <TableCell component="th">{getTrans('order_date')}</TableCell>
                        <TableCell component="th">{getTrans('order_clientName')}</TableCell>
                        <TableCell component="th">{getTrans('order_item_name')}</TableCell>
                        <TableCell component="th">{getTrans('order_item_color')}</TableCell>
                        <TableCell component="th">{getTrans('order_item_label')}</TableCell>
                        <TableCell component="th">{getTrans('order_item_gender')}</TableCell>
                        <TableCell component="th">{getTrans('order_item_size')}</TableCell>
                        <TableCell component="th">{getTrans('order_item_amount')}</TableCell>
                        <TableCell component="th">{getTrans('order_user_comment')}</TableCell>
                        <TableCell component="th">{getTrans('order_admin_comment')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders && orders.map((order) => {
                        return order.items.map((item, index) => (
                            <TableRow key={`${order._id}_item_${item._id}`}>
                                <TableCell>{order.shopId}</TableCell>
                                <TableCell>{moment(order.date).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                <TableCell>{order.clientName}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.color}</TableCell>
                                <TableCell>{item.label}</TableCell>
                                <TableCell>{item.gender}</TableCell>
                                <TableCell>{item.size}</TableCell>
                                <TableCell>{item.amount}</TableCell>
                                <TableCell>{order.userComment}</TableCell>
                                <TableCell>{order.adminComment}</TableCell>
                            </TableRow>
                        ))
                    })}
                </TableBody>
            </Table>
        </TableContainer>);
}

const BlockHeader = styled(Typography)`
  margin: 1rem 0 !important;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`