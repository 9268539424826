import React, { useState, useEffect, useCallback } from "react";
import { restInstance } from "../domain/http/rest";

export const TracksContext = React.createContext("tracks");

export const TracksProvider = ({ children }) => {
  const [tracks, setTracks] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState(null);

  const loadTracks = async () => {
    const { data } = await restInstance.get("/tracks");
    setTracks(data);
  };

  const preload = useCallback(async () => {
    await loadTracks();
  }, []);

  return (
    <TracksContext.Provider
      value={{
        tracks,
        selectedTrack,
        viewTrack: setSelectedTrack,
        loadTracks: preload
      }}
    >
      {children}
    </TracksContext.Provider>
  );
};
