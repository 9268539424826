import React, {useContext} from "react";
import styled from "styled-components";

import {OrdersContext} from "../../../contexts/Orders";
import {Button, Tab, Tabs} from "@material-ui/core";
import {OrderStatus} from "../../../domain/orders/OrderStatus";
import {getTrans} from "../../../translations";
import {OrdersTab} from "./OrdersTab";

export const OrdersContainer = () => {
    const ordersContext = useContext(OrdersContext);
    const handleChangeTab = (e, newValue) => {
        ordersContext.setStatus(newValue);
    }

    return (
        <Container>
            <ControlContainer>
                <Tabs value={ordersContext.status} onChange={handleChangeTab} indicatorColor="primary">
                    {Object.keys(OrderStatus).filter(key => OrderStatus[key] !== OrderStatus.EXPORTED).map(key => (
                        <Tab key={key} label={getTrans(OrderStatus[key])} value={OrderStatus[key]}/>))}
                </Tabs>
                {ordersContext.status !== OrderStatus.IN_WORK && <div>
                    <Button color="primary" variant="contained" onClick={ordersContext.generateReport}
                            style={{'marginRight': '1rem'}}>{getTrans('export_label')}</Button>
                    <Button color="secondary" variant="contained"
                            onClick={ordersContext.transferOrders}>{getTrans('transfer_label')}</Button>
                </div>}
            </ControlContainer>
            <OrdersTab status={OrderStatus.INCOMING}/>
            <OrdersTab status={OrderStatus.IN_WORK}/>
        </Container>);
}

const Container = styled.div`
  margin: 1rem auto;
  padding: 1rem;
  max-width: 1280px;
`;

const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
