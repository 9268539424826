import { Button, Card, Typography } from "@material-ui/core";
import { TracksContext } from "../../../contexts/Tracks";
import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const TracksList = () => {
  const tracksContext = useContext(TracksContext);

  return (
    <div>
      {tracksContext.tracks.map((t) => (
        <TrackListItem
          track={t}
          key={t.resourceId}
          onViewClicked={() => {
            tracksContext.viewTrack(t);
          }}
        />
      ))}
    </div>
  );
};

const TrackListItem = ({ track, onViewClicked }) => {
  return (
    <StyledCard>
      <Row>
        <StyledTypography variant="body1">Uploaded at</StyledTypography>
        <StyledTypography variant="caption">
          {track.uploadedAt}
        </StyledTypography>
        <StyledTypography variant="caption">
          {track.resourceId}
        </StyledTypography>
        <StyledTypography variant="caption">{track.size} Mb</StyledTypography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          onClick={onViewClicked}
          to={`/track/${track.resourceId}`}
        >
          Посмотреть
        </Button>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 1rem;
`;

const StyledTypography = styled(Typography)`
  margin: 0 0.75rem !important;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem;
`;
