import React, {useContext} from "react";
import styled from 'styled-components';
import {OrdersContext} from "../../../contexts/Orders";
import {OrderStatus} from "../../../domain/orders/OrderStatus";
import {Orders} from "./Orders";
import {Typography} from "@material-ui/core";
import {getTrans} from "../../../translations";

export const OrdersTab = ({status}) => {
    const ordersContext = useContext(OrdersContext);
    if (status !== ordersContext.status) {
        return null;
    }

    if (status === OrderStatus.IN_WORK) {
        return <Orders status={status}/>
    }

    return (
        <div>
            <BlockHeader component="h1" variant="subtitle1" color="primary">{getTrans('new_orders')}</BlockHeader>
            <Orders status={status}/>
            <BlockHeader component="h1" variant="subtitle1" color="primary">{getTrans('exported_orders')}</BlockHeader>
            <Orders status={OrderStatus.EXPORTED}/>
        </div>
    )
}

const BlockHeader = styled(Typography)`
  margin: 2rem 0 !important;
`;