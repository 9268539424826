import React from "react";
import { ChallengesProvider } from "../../contexts/Challenges";
import { ChallengeContainer } from "../components/challenges/ChallengeContainer";

import Header, { pages } from "./Header";

export const ChallengePage = () => {
    return (
        <ChallengesProvider>
            <Header activePage={pages.CHALLENGES} />
            <ChallengeContainer/>
        </ChallengesProvider>
    );
};