import React, { useEffect, useState } from "react";

import { restInstance, setToken } from "../domain/http/rest";

export const AuthContext = React.createContext("auth");

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    const loadAuth = async () => {
        const token = localStorage.getItem("auth_token");
        if (!token)
            return setLoading(false);

        const response = await restInstance.get("/auth");
        if (response.status === 200) {
            setCurrentUser(response.data);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } else {
            logout();
        }
    };

    const login = async ({ username, password }) => {
        setError(null);
        const { data } = await restInstance.post("/auth", { username, password });
        if (!data.token) return setError("Invalid password or username");

        setToken(data.token);
        await loadAuth();
    };

    const logout = () => {
        setToken(null);
        setCurrentUser(null);
        setLoading(false);
    };


    useEffect(() => {
        loadAuth()
    }, []);

    return (
        <AuthContext.Provider value={{ login, logout, loading, error, currentUser }}>
            {children}
        </AuthContext.Provider>
    );
};
