import React, {useContext} from "react";
import {ChallengesContext} from "../../../contexts/Challenges";
import {MenuItem, Select} from "@material-ui/core";

const challengesFilterTypeToName = {
    "all": "Все забеги",
    "active": "Активные",
    "passed": "Закрытые"
};

export const ChallengeFilter = () => {
    const challengesContext = useContext(ChallengesContext);

    const handleChange = (event) => {
        challengesContext.setChallengeFilter(event.target.value);
    };

    return (
        <Select value={challengesContext.challengeFilter} onChange={handleChange}>
            {Object.keys(challengesFilterTypeToName).map(key => (
                <MenuItem key={key} value={key}>{challengesFilterTypeToName[key]}</MenuItem>
            ))}
        </Select>
    );
}