export default {
    'incoming': 'Входящие',
    'exported': 'Экспортированные',
    'in_work': 'Переданные в МДК',
    'order_number': '№ заказа',
    'order_date': 'Дата',
    'order_clientName': 'ФИО',
    'order_item_amount': 'Кол-во',
    'order_item_color': 'Цвет',
    'order_item_gender': 'Пол',
    'order_item_label': 'Надпись',
    'order_item_name': 'Товар',
    'order_item_size': 'Размер',
    'new_orders': 'Новые заказы',
    'no_orders_with_status': 'Заказов со статусом "%s" нет',
    'exported_orders': 'Экспортированные заказы',
    'export_label': 'Экспортировать',
    'logout_label': 'Выйти',
    'transfer_label': 'Передано в МДК',
    'order_user_comment': 'Комментарий пользователя',
    'order_admin_comment': 'Комментарий администратора',
}