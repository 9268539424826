import React, {useContext} from 'react';
import styled from 'styled-components';
import {Button} from "@material-ui/core";
import {Link} from 'react-router-dom';

import {ChallengesList} from './ChallengesList';
import {AuthContext} from '../../../contexts/Auth';
import {isCanManageChallenges} from '../../../domain/resolvePermission';
import {ChallengeFilter} from "./ChallengeFilter";


export const ChallengesContainer = () => {
    const authContext = useContext(AuthContext);

    return (<Container>
        <ControlContainer>
            <ChallengeFilter/>
            {isCanManageChallenges(authContext.currentUser) &&
                <Button component={Link} to="/challenges/new" variant="contained" color="primary">Запустить
                    новый</Button>}
        </ControlContainer>
        <ChallengesList/>
    </Container>);
};

const Container = styled.div`
max-width: 1024px;
margin: 1rem auto;
`;

const ControlContainer = styled.div`
display: flex;
justify-content: space-between;
margin: 1rem 0;
`;