import React, {useContext, useMemo, useState} from "react";
import styled from "styled-components";
import {
    makeStyles, Typography, TextField, Select, MenuItem, ListSubheader,
    FormControl, InputLabel, Button, IconButton, FormControlLabel, Checkbox
} from "@material-ui/core";
import {Add, FileCopyOutlined, Remove} from "@material-ui/icons";
import {DateTimePicker} from "@material-ui/pickers"
import {ChallengesContext} from "../../../contexts/Challenges";
import {DeleteChallengeDialog} from "./DeleteChallengeDialog";
import moment from "moment";
import {getNextDay, SATURDAY} from "./dateUtil";
import {ImageLoaderType, ImageLoader} from "../shared/ImageLoader";
import {useSnackbar} from "notistack";
import {copyToClipboard} from "../../../utils/copyToClipboard";
import {get as _get} from "lodash";

const emptyDistance = {
    distKey: "",
    meter: 0,
    meterMin: 0
};


const openSingle = ["max_distance", "max_distance_simple", "stairs", "invariable_pulse", "invariable_pace", "highland", "year_plan", "velo_with_points", "cars_max_distance", "max_track_distance"];

const withGaol = ["max_distance", "team_max_distance"];

export const LaunchChallenge = ({registration}) => {
    const classes = useStyles();

    const challengesContext = useContext(ChallengesContext);
    const {enqueueSnackbar} = useSnackbar();

    const formulaTypes = useMemo(() => {
        const defaultTypes = {
            team: [],
            single: [],
            open: [],
            closed: []
        };
        const {formulas} = challengesContext;
        if (!formulas || !formulas.single) return defaultTypes;
        const result = {...defaultTypes};
        result.team = formulas.team.map(f => f.type);
        result.single = formulas.single.map(f => f.type);
        result.open = [...result.team, ...openSingle];
        result.closed = formulas.single.filter(f => result.open.indexOf(f.type) < 0).map(f => f.type);
        return result;
    }, [challengesContext.formulas]);

    const registrationId = registration?.id;

    const [isDialog, setDialog] = useState(false);
    const [name, setName] = useState(registration ? registration.name : '');
    const [description, setDescription] = useState(registration ? registration.description : '');
    const [formula, setFormula] = useState(registration ? registration.challenge.formulaType : "simple");
    const [withStartingNumber, setWithStartingNumber] = useState(registration ? registration.withStartingNumber : false);
    const [runPlatform, setRunPlatform] = useState(registration ? registration.challenge.runPlatform : "s10");
    const [minTeamParticipants, setMinParticipants] = useState(registration ? registration.minTeamParticipants : 1);
    const [maxTeamParticipants, setMaxParticipants] = useState(registration ? registration.maxTeamParticipants : 1);
    const [locations, setLocations] = useState(registration ? registration.locations : "");
    const [locationsDescription, setLocationsDescription] = useState(registration ? registration.locationsDescription : '');
    const [startreg, setStartreg] = useState(registration ? registration.startreg : "");
    const [isPrivate, setPrivate] = useState(registration ? registration.isPrivate : true);
    const [isCommercial, setCommercial] = useState(registration ? registration.challenge.isCommercial : false);
    //timeline
    const nextSaturday = getNextDay(SATURDAY);
    const endNextSunday = moment(nextSaturday).add(1, 'days').endOf('day');

    const regStartDefault = moment(nextSaturday).subtract(1, 'week').toDate();
    const regStopDefault = endNextSunday.toDate();
    const runStartDefault = nextSaturday.toDate();
    const runStopDefault = endNextSunday.toDate();
    const protocolOpenAllDefault = endNextSunday.toDate();

    const [regStart, setRegStart] = useState(registration ? moment(registration.regStart).utcOffset(6, true).toDate() : regStartDefault);
    const [regStop, setRegStop] = useState(registration ? moment(registration.regStop).utcOffset(6, true).toDate() : regStopDefault);
    const [runStart, setRunStart] = useState(registration ? moment(registration.challenge.runStartDate).utcOffset(6, true).toDate() : runStartDefault);
    const [runStop, setRunStop] = useState(registration ? moment(registration.challenge.runStopDate).utcOffset(6, true).toDate() : runStopDefault);
    const [protocolOpenAll, setProtocolOpenAll] = useState(registration ? moment(registration.challenge.protocolOpenAllDate).utcOffset(6, true).toDate() : protocolOpenAllDefault);
    const [rulesLink, setRulesLink] = useState(registration ? registration.rulesLink : '');
    const [seasonId, setSeasonId] = useState(registration ? registration.challenge.seasonId : null);
    // images
    const [bannerImg, setBannerImg] = useState(registration ? registration.bannerImgUrl : null);
    const [storyImg, setStoryImg] = useState(registration ? registration.storyImgUrl : null);

    const [distances, setDistances] = useState(registration?.challenge?.distances?.length ? registration.challenge.distances : [{...emptyDistance}]);
    const [goal, setGaol] = useState(registration?.challenge?.goal ? registration.challenge.goal : 0);

    // settings
    const settings = _get(registration, "challenge.settings");
    const [isDiplomaFree, setDiplomaFree] = useState(settings ? settings.isDiplomaFree : false);
    const [withAdds, setWithAdds] = useState(settings ? settings.withAdds : true);
    const [withHeaderFooter, setWithHeaderFooter] = useState(settings ? settings.withHeaderFooter : true);
    const [withCommunityChat, setWithCommunityChat] = useState(settings ? settings.withCommunityChat : true);
    const [withAppeal, setWithAppeal] = useState(settings ? settings.withAppeal : true);
    const [communityChatLink, setChatLink] = useState(settings ? settings.communityChatLink : "https://t.me/S10_Challenges");
    const [appealEmail, setAppealEmail] = useState(settings ? settings.appealEmail : "appeal@s10.run");
    const [appealLink, setAppealLink] = useState(settings ? settings.appealLink : "https://s10.run/support");
    const [withChallengesBanners, setWithChallengesBanners] = useState(settings ? settings.withChallengesBanners : true);
    const [isChallengePaid, setPaidChallenge] = useState(settings ? settings.isChallengePaid : true);


    const handleAddingDistance = () => {
        setDistances([...distances, {...emptyDistance}]);
    };

    const handleDeletingDistance = (index) => {
        const newDistances = distances.slice(0)
        newDistances.splice(index, 1);
        setDistances(newDistances);
    };

    const handleChangingDistance = (distIndex, fieldKey, value) => {
        const newDistances = [...distances];
        newDistances[distIndex][fieldKey] = value;
        if (fieldKey === "meter") {
            newDistances[distIndex].meterMin = value;
        }
        setDistances(newDistances);
    };

    const isGoalFormula = withGaol.indexOf(formula) >= 0;

    const handleLaunchChallenge = async () => {
        const validDistances = distances.filter(d => Boolean(d.distKey));
        const registration = {
            regStart: moment(regStart).startOf('day').utcOffset(0, true),
            regStop: moment(regStop).endOf('day').utcOffset(0, true),
            startreg,
            name,
            description,
            maxTeamParticipants: formulaTypes.single.indexOf(formula) >= 0 ? 1 : maxTeamParticipants,
            minTeamParticipants: formulaTypes.single.indexOf(formula) >= 0 ? 1 : minTeamParticipants,
            locations,
            locationsDescription,
            rulesLink,
            isPrivate: isPrivate ? 1 : 0,
            withStartingNumber: withStartingNumber && formulaTypes.single.indexOf(formula) >= 0 ? 1 : 0,
        }
        const challenge = {
            runStart: moment(runStart).startOf('day').utcOffset(0, true),
            runStop: moment(runStop).endOf('day').utcOffset(0, true),
            protocolOpenAll: moment(protocolOpenAll).endOf('day').utcOffset(0, true),
            name,
            description,
            formula,
            runPlatform,
            startreg,
            seasonId,
            isCommercial: Number(isCommercial),
            goal: isGoalFormula ? Math.abs(goal) : 0,
        };
        const settings = {
            isDiplomaFree: Number(isDiplomaFree),
            withAdds: Number(withAdds),
            withHeaderFooter: Number(withHeaderFooter),
            withAppeal: Number(withAppeal),
            withCommunityChat: Number(withCommunityChat),
            withChallengesBanners: Number(withChallengesBanners),
            isChallengePaid: Number(isChallengePaid)
        };
        if (withAppeal) {
            settings.appealEmail = appealEmail;
            settings.appealLink = appealLink;
        }
        if (withCommunityChat) {
            settings.communityChatLink = communityChatLink;
        }

        registrationId ?
            await challengesContext.updateChallenge(registrationId, registration, challenge, validDistances, bannerImg, storyImg, settings)
            : await challengesContext.launchChallenge(registration, challenge, validDistances, bannerImg, storyImg, settings);
        window.location = '/'
    };

    if (!challengesContext.formulas || !challengesContext.formulas.single) return null;

    const baseDomain = runPlatform === 's10' ? 'challenges.s10' : 'jgrkz';
    const regPath = runPlatform === 's10' ? 'startreg' : 'entry';
    const regLink = `https://${baseDomain}.run/${regPath}?${startreg}`;

    const handleCopy = () => {
        copyToClipboard(regLink);
        enqueueSnackbar("Ссылка скопирована");
    };

    const handleChangeFormula = (e) => {
        const chosenFormula = e.target.value;
        setFormula(chosenFormula);
        if (formulaTypes.open.indexOf(chosenFormula) >= 0) {
            setProtocolOpenAll(runStart);
        }

        if (formulaTypes.closed.indexOf(chosenFormula) >= 0) {
            setProtocolOpenAll(runStop);
        }
    }

    const handleChangeSeason = (e) => setSeasonId(e.target.value);

    const handleTemplate = (value, cb) => {
        cb(value);
    }

    const preventScrollInputChange = (e) => {
        if (e.target instanceof HTMLElement) {
            e.target.blur()
        }
    }

    return (
        <Container>
            {registrationId ?
                <>
                    <DeleteChallengeDialog
                        isDialog={isDialog}
                        setDialog={setDialog}
                        challengeId={registration.challenge.id}
                    />
                    <Typography variant="h5">Обновить челлендж</Typography>
                    <TypographyWithMargin variant="h6">Регистрация</TypographyWithMargin>
                </>
                : <>
                    <Typography variant="h5">Запустить новый челлендж</Typography>
                    <TypographyWithMargin variant="h6">Регистрация</TypographyWithMargin>
                </>
            }
            <ImageLoaderContainer>
                <ImageLoader className={classes.horizontalLoader} type={ImageLoaderType.HORIZONTAL}
                             onChange={setBannerImg} value={bannerImg}/>
                <ImageLoader type={ImageLoaderType.VERTICAL} onChange={setStoryImg} value={storyImg}/>
            </ImageLoaderContainer>

            <TextField
                label={"Название"}
                onChange={(e) => setName(e.target.value)}
                required
                className={classes.input}
                variant="outlined"
                value={name}
            />
            <TextField
                label={"Описание"}
                onChange={(e) => setDescription(e.target.value)}
                className={classes.input}
                inputProps={{maxLength: 90}}
                variant="outlined"
                value={description}
                multiline
            />
            <Template value={"Побеждает тот, кто покажет меньшее время на финише."} cb={setDescription}/>
            <Template
                value={"Побеждает тот, у кого лучшая разница в процентах между личным рекордом и результатом на выбранной вами дистанции"}
                cb={setDescription}/>
            <Template
                value={"Передай эстафетную палочку онлайн. Побеждает команда, которая покажет меньшее время на финише. "}
                cb={setDescription}/>
            <Template value={"Побеждает самый внимательный"} cb={setDescription}/>

            <TypographyWithMargin variant="caption">Стартовое окно</TypographyWithMargin>
            <PickerContainer>
                <DateTimePicker value={runStart} onChange={setRunStart} inputVariant="outlined" ampm={false}/>
                <DateTimePicker value={runStop} onChange={setRunStop} inputVariant="outlined" ampm={false}/>
            </PickerContainer>

            <FormControl>
                <InputLabel htmlFor="platform-select">Платформа</InputLabel>
                <Select
                    label={"Платформа"}
                    required
                    variant="outlined"
                    className={classes.input}
                    value={runPlatform}
                    id="platform-select"
                    onChange={(e) => setRunPlatform(e.target.value)}
                >
                    <MenuItem value={"s10"}>S10</MenuItem>
                    <MenuItem value={"jgrkz"}>JGRKZ</MenuItem>
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel htmlFor="season-select">Сезон</InputLabel>
                <Select
                    label={"Сезон"}
                    required
                    variant="outlined"
                    className={classes.input}
                    value={seasonId}
                    id="season-select"
                    onChange={handleChangeSeason}
                >

                    {challengesContext.seasons.map(s => <MenuItem value={s.id}
                                                                  key={s.id}>{s.name}</MenuItem>)}
                </Select>
            </FormControl>

            <TextField
                label={"Стартрег"}
                required
                type="number"
                onChange={(e) => setStartreg(e.target.value)}
                onWheel={preventScrollInputChange}
                className={classes.input}
                variant="outlined"
                value={startreg}
            />
            <Typography variant="body2" className={classes.link} onClick={handleCopy}><FileCopyOutlined/>{regLink}
            </Typography>

            <TypographyWithMargin variant="h6">Формула - правила</TypographyWithMargin>

            <FormControl>
                <InputLabel htmlFor="formula-select">Формула</InputLabel>
                <Select
                    label={"Формула"}
                    required
                    variant="outlined"
                    className={classes.input}
                    value={formula}
                    id="formula-select"
                    onChange={handleChangeFormula}
                >
                    <ListSubheader>Одиночные</ListSubheader>
                    {challengesContext.formulas.single.map(f => <MenuItem value={f.type}
                                                                          key={f.type}>{f.name}</MenuItem>)}
                    <ListSubheader>Командные</ListSubheader>
                    {challengesContext.formulas.team.map(f => <MenuItem value={f.type}
                                                                        key={f.type}>{f.name}</MenuItem>)}
                </Select>
            </FormControl>

            {formulaTypes.single.indexOf(formula) >= 0 &&
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={withStartingNumber}
                            onChange={(e) => setWithStartingNumber(e.target.checked)}
                            color="secondary"
                            disabled={registration ? moment(registration.regStart).isBefore() : false}
                        />
                    }
                    className={classes.checkboxContainer}
                    label="Выдать стартовый номер"
                />}
            <TypographyWithMargin variant="caption">Дата открытия протокола</TypographyWithMargin>
            <PickerContainer>
                <DateTimePicker fullWidth value={protocolOpenAll} onChange={setProtocolOpenAll} inputVariant="outlined"
                                ampm={false}/>
            </PickerContainer>

            <TextField
                label={"Ссылка на правила"}
                onChange={(e) => setRulesLink(e.target.value)}
                className={classes.input}
                variant="outlined"
                value={rulesLink}
            />
            <Template value={"https://s10.run/tilda/85144/page"} cb={setRulesLink}/>
            <Template value={"https://s10.run/"} cb={setRulesLink}/>

            {formulaTypes.team.indexOf(formula) >= 0 && <>
                <TextField
                    label={"Минимально участников в команде"}
                    required
                    type="number"
                    onChange={(e) => setMinParticipants(e.target.value)}
                    onWheel={preventScrollInputChange}
                    className={classes.input}
                    variant="outlined"
                    value={minTeamParticipants}
                />
                <TextField
                    label={"Максимально участников в команде"}
                    required
                    type="number"
                    onChange={(e) => setMaxParticipants(e.target.value)}
                    onWheel={preventScrollInputChange}
                    className={classes.input}
                    variant="outlined"
                    value={maxTeamParticipants}
                />
            </>}

            <TextField
                label={"Локации"}
                onChange={(e) => setLocations(e.target.value)}
                className={classes.input}
                variant="outlined"
                value={locations}
            />
            <TextField
                label={"Описание локаций"}
                onChange={(e) => setLocationsDescription(e.target.value)}
                className={classes.input}
                variant="outlined"
                value={locationsDescription}
                multiline
            />

            <TypographyWithMargin variant="caption">Окно регистрации</TypographyWithMargin>
            <PickerContainer>
                <DateTimePicker value={regStart} onChange={setRegStart} inputVariant="outlined" ampm={false}/>
                <DateTimePicker value={regStop} onChange={setRegStop} inputVariant="outlined" ampm={false}/>
            </PickerContainer>
            {isGoalFormula && <TextField
                label={"Цель (км)"}
                required
                type="number"
                onChange={(e) => setGaol(e.target.value)}
                onWheel={preventScrollInputChange}
                className={classes.input}
                variant="outlined"
                value={goal}
            />}
            <Typography variant="h6" className={classes.distanceHeaderContainer}>Дистанции
                <IconButton color="primary" variant="contained"
                            onClick={handleAddingDistance}><Add/></IconButton></Typography>
            {distances.map((d, i) => (
                <div key={i}>
                    <Typography variant="subtitle2" className={classes.distanceHeaderContainer}>{`Дистанция #${i + 1}`}
                        {distances.length > 1 && <IconButton variant="contained"
                                                             onClick={handleDeletingDistance.bind(this, i)}><Remove/></IconButton>}</Typography>
                    <TextField
                        label={"Ключ дистанции"}
                        onChange={(e) => handleChangingDistance(i, "distKey", e.target.value)}
                        required
                        className={classes.input}
                        variant="outlined"
                        value={d.distKey}
                    />
                    <Template value={"3 km"} cb={handleChangingDistance.bind(undefined, i, "distKey", "3 km")}/>
                    <Template value={"Марафон"} cb={handleChangingDistance.bind(undefined, i, "distKey", "Марафон")}/>
                    <Template value={"Полумарафон"}
                              cb={handleChangingDistance.bind(undefined, i, "distKey", "Полумарафон")}/>

                    <TextField
                        label={"Длина"}
                        required
                        type="number"
                        onChange={(e) => handleChangingDistance(i, "meter", e.target.value)}
                        onWheel={preventScrollInputChange}
                        className={classes.input}
                        variant="outlined"
                        value={d.meter}
                    />

                    <TextField
                        label={"Минимальный порог"}
                        type="number"
                        onChange={(e) => handleChangingDistance(i, "meterMin", e.target.value)}
                        onWheel={preventScrollInputChange}
                        className={classes.input}
                        variant="outlined"
                        value={d.meterMin}
                    />
                </div>
            ))}
            <TypographyWithMargin variant="h6">Доп. настройки</TypographyWithMargin>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isPrivate}
                        onChange={(e) => setPrivate(e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Закрытый забег"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isCommercial}
                        onChange={(e) => setCommercial(e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Корпоративный забег"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={isChallengePaid}
                        onChange={(e) => setPaidChallenge(e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Платная рега"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={!withAppeal}
                        onChange={(e) => setWithAppeal(!e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Скрыть аппеляции"
            />

            {
                withAppeal && <TextField
                    label={"Ссылка для аппеляций"}
                    onChange={(e) => setAppealLink(e.target.value)}
                    className={classes.input}
                    type={"url"}
                    required={withAppeal}
                    variant="outlined"
                    value={appealLink}
                />
            }

            <FormControlLabel
                control={
                    <Checkbox
                        checked={!withCommunityChat}
                        onChange={(e) => setWithCommunityChat(!e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Скрыть ссылку на чат"
            />

            {
                withCommunityChat && <TextField
                    label={"Ссылка на чат"}
                    onChange={(e) => setChatLink(e.target.value)}
                    type={"url"}
                    className={classes.input}
                    required={withCommunityChat}
                    variant="outlined"
                    value={communityChatLink}
                />
            }


            <FormControlLabel
                control={
                    <Checkbox
                        checked={isDiplomaFree}
                        onChange={(e) => setDiplomaFree(e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Бесплатный диплом"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={!withAdds}
                        onChange={(e) => setWithAdds(!e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Скрыть рекламу в протоколе"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={!withChallengesBanners}
                        onChange={(e) => setWithChallengesBanners(!e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Скрыть баннеры забегов"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={!withHeaderFooter}
                        onChange={(e) => setWithHeaderFooter(!e.target.checked)}
                        color="secondary"
                    />
                }
                className={classes.checkboxContainer}
                label="Скрыть хедер и футер"
            />

            <Button type="submit" color="secondary" className={classes.input} variant="outlined"
                    onClick={handleLaunchChallenge}>
                {registrationId ? 'Обновить' : 'Запустить'}
            </Button>
            {registrationId &&
                <Button color="secondary" className={classes.input} variant="contained"
                        onClick={() => setDialog(!isDialog)}>Удалить</Button>}

        </Container>
    )
};

export const Template = ({value, cb}) => {
    const classes = useStyles();

    return (
        <div className={classes.templateContainer}>
            <Typography variant="body2">{value}</Typography>
            <Button variant="outlined" color="primary" className={classes.templateCopy}
                    onClick={
                        cb.bind(undefined, value)
                    }>Вставить</Button>
        </div>
    )
}

const Container = styled.div`
  max-width: 1024px;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;

`;

const TypographyWithMargin = styled(Typography)`
  margin-top: 1rem;
`;

const PickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  margin: .5rem 0 1rem;
`;

const ImageLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;


const useStyles = makeStyles({
    input: {
        width: "400px",
        marginBottom: "1rem",
        marginRight: "1rem"
    },
    checkboxContainer: {
        marginBottom: "1rem"
    },
    distanceHeaderContainer: {
        marginTop: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "400px",
        marginBottom: "0.5rem"
    },
    horizontalLoader: {
        marginRight: "1rem"
    },
    link: {
        cursor: "pointer",
        marginBottom: "1.5rem",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
    },
    templateContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.5rem",
        border: "1px solid #eee",
        borderRadius: "5px",
        width: "calc(400px - 2rem)",
        marginBottom: "1.5rem",
        background: "#eee"
    },
    templateCopy: {
        flexShrink: 0
    }
});