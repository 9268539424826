import React, {useContext} from "react";
import styled from "styled-components";
import {AppBar, Toolbar, Typography, Button} from "@material-ui/core";
import {getTrans} from "../../translations";
import {AuthContext} from "../../contexts/Auth";
import {isCanManageClubs, isCanViewShop, isCanViewTracks} from "../../domain/resolvePermission";
import { Link } from "react-router-dom";


export default ({activePage}) => {
    const authContext = useContext(AuthContext);
    return (
        <AppBar position="static">
            <StyledToolbar>
                <LinkContainer>
                    <StyledTypography variant="h6">
                        <StyledLink to="/"><Logo src={require('../../assets/logo.svg')}/></StyledLink>
                    </StyledTypography>
                    <StyledTypography active={activePage === pages.CHALLENGES ? pages.CHALLENGES : null} variant="h6">
                        <StyledLink to="/">Challenges</StyledLink>
                    </StyledTypography>
                    <StyledTypography active={activePage === pages.STATISTICS ? pages.STATISTICS : null} variant="h6">
                        <StyledLink to="/statistics">Statistics</StyledLink>
                    </StyledTypography>
                    {isCanViewShop(authContext.currentUser) &&
                        <StyledTypography active={activePage === pages.SHOP ? pages.SHOP : null} variant="h6">
                            <StyledLink to="/shop">Shop</StyledLink>
                        </StyledTypography>}
                    {isCanManageClubs(authContext.currentUser) &&
                        <StyledTypography active={activePage === pages.CLUBS ? pages.CLUBS : null} variant="h6">
                            <StyledLink to="/clubs">Clubs</StyledLink>
                        </StyledTypography>}
                    {isCanViewTracks(authContext.currentUser) &&
                        <StyledTypography active={activePage === pages.TRACKS ? pages.TRACKS : null} variant="h6">
                            <StyledLink to="/tracks">Tracks</StyledLink>
                        </StyledTypography>}
                </LinkContainer>
                <Button onClick={authContext.logout} color="inherit">{getTrans('logout_label')}</Button>
            </StyledToolbar>
        </AppBar>
    );
};

export const pages = {
    SHOP: "shop",
    CHALLENGES: "challenges",
    STATISTICS: "statistics",
    CLUBS: "clubs",
    TRACKS: "tracks"
};

const StyledTypography = styled(Typography)`
  background: ${(props) => (props.active ? "#324191" : "")};
`;

const StyledLink = styled(Link)`
  color: white;
  margin: 1rem;
  display: block;
  text-decoration: none;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 36px;
`;