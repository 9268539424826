import React from "react";
import { ClubsProvider } from "../../../contexts/Clubs";
import { ClubsContainer } from "../../components/clubs/ClubsContainer";

import Header, { pages } from "../Header";

export const ClubsPage = () => {
    return (
        <ClubsProvider>
            <Header activePage={pages.CLUBS} />
            <ClubsContainer />
        </ClubsProvider>
    );
};