import React from "react";
import { ChallengesProvider } from "../../contexts/Challenges";
import { ChallengesContainer } from "../components/challenges/ChallengesContainer";

import Header, { pages } from "./Header";

export const ChallengesPage = () => {
    return (
        <ChallengesProvider>
            <Header activePage={pages.CHALLENGES} />
            <ChallengesContainer />
        </ChallengesProvider>
    );
};