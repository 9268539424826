import React, { useContext } from "react";
import { ClubsContext } from "../../../contexts/Clubs";
import { makeStyles, Dialog, DialogTitle, Grid, Button } from "@material-ui/core";


export const DeleteClubDialog = ({ isDialog, setDialog, clubId }) => {
    const classes = useStyles();
    const { deleteClub } = useContext(ClubsContext);
    
    const handleClick = async () => {
        await deleteClub(clubId)
        window.location = '/clubs'
    };

    return (
        <Dialog open={isDialog} onClose={() => setDialog(!isDialog)}>
            <DialogTitle>Архивировать клуб?</DialogTitle>
            <Grid className={classes.dialogActions} container justify="space-around">
                <Grid item xs={5}>
                    <Button fullWidth variant="contained" onClick={handleClick} color="secondary">Удалить</Button>
                </Grid>
                <Grid item xs={5}>
                    <Button fullWidth variant="contained" onClick={() => setDialog(!isDialog)}>Отменить</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}


const useStyles = makeStyles({
    dialogActions: {
        paddingBottom: "1rem"
    }
})