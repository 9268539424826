import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';

import { ClubsList } from './ClubsList';
import { AuthContext } from '../../../contexts/Auth';
import { isCanManageClubs } from '../../../domain/resolvePermission';
import {ClubsFilter} from "./ClubsFilter";


export const ClubsContainer = () => {
    const authContext = useContext(AuthContext);

    return <Container>
        <ControlContainer>
            <ClubsFilter/>
            {isCanManageClubs(authContext.currentUser) && 
                <Button component={Link} to="/clubs/new" variant="contained" color="primary" >Создать клуб</Button>}
        </ControlContainer>
        <ClubsList/>
    </Container>
};

const Container = styled.div`
max-width: 1024px;
margin: 1rem auto;
`;

const ControlContainer = styled.div`
display: flex;
justify-content: space-between;
margin: 1rem 0;
`;