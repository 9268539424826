import { TracksContext } from "../../../contexts/Tracks";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TrackView } from "./TrackView";
import styled from "styled-components";

export const TrackContainer = () => {
  const { selectedTrack } = useContext(TracksContext);
  const { resourceId } = useParams();

  if (selectedTrack === null) {
    window.location.href = "/tracks";
  }

  useEffect(() => {
    if (selectedTrack.resourceId !== resourceId) {
      window.location.href = "/tracks";
    }
  }, [selectedTrack]);

  return (
    <Container>
      <TrackView gpxFileUrl={selectedTrack.link} />
    </Container>
  );
};

const Container = styled.div`
  max-width: 1024px;
  margin: 1rem auto;
`;
