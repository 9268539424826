import React from "react";
import { ClubsProvider } from "../../../contexts/Clubs";
import { ClubContainer } from "../../components/clubs/ClubContainer";

import Header, { pages } from "../Header";

export const ClubPage = () => {
    return (
        <ClubsProvider>
            <Header activePage={pages.CLUBS} />
            <ClubContainer/>
        </ClubsProvider>
    );
};